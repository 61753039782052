import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material'
import './purchaseorder.css'
import moment from 'moment'

const PoInvoiceTemplate = (props) => {
    const { purchaseOrderDetail, rows } = props

    const vendor = rows.length > 0 ? rows[0].vendor || 'N/A' : 'N/A'

    const formatDate = (date) => {
        if (!date) return 'N/A'
        return moment(date).format('DD/MM/YYYY')
    }

    const allowedKeys = [
        { key: 'poNumber', label: 'PO Number' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'shipTo', label: 'Ship To' },
        { key: 'shipVia', label: 'Ship Via' },
        { key: 'status', label: 'Status' },
        { key: 'poDate', label: 'PO Date' },
        { key: 'poLoadDate', label: 'PO Load Date' },
        { key: 'ready_date', label: 'Ready Date' },
        { key: 'poDueDate', label: 'PO Due Date' },
        { key: 'vendor', label: 'Vendor' }
    ]

    return (
        <div className="pdf_div pdf-page size-a4">
            <div className='top_div'>
                <Box
                    className="header_top"
                >
                    <Typography
                        variant="h5"
                        align="left"
                        style={{ fontWeight: 'bold' }}
                    >
                        Purchase Order #&nbsp;{purchaseOrderDetail?.poNumber}
                    </Typography>

                    <Typography variant="p" gutterBottom style={{ fontWeight: 'bold' }}>
                        {purchaseOrderDetail?.status}
                    </Typography>
                </Box>
            </div>

            <Grid
                container
                spacing={3}
                className='po_details'
            >
                {allowedKeys.map(({ key, label }) => (
                    <Grid item xs={6} sm={4} md={3} key={key}>
                        <Typography
                            variant="body2"
                            className='data_key'
                        >
                            {label}
                        </Typography>
                        <Typography variant="body2" style={{ color: '#555' }}>
                            {key === 'vendor' ? vendor : (key.endsWith('Date') ? formatDate(purchaseOrderDetail[key]) : purchaseOrderDetail[key]) || 'N/A'}
                        </Typography>
                    </Grid>
                ))}
            </Grid>

            <Box mt={3} mb={2}>
                <Typography
                    variant="p"
                    gutterBottom
                    className='product_heading'
                >
                    Products Details
                </Typography>
            </Box>

            <TableContainer className='table_product' >
                <Table size="small" className='inner_table'>
                    <TableHead>
                        <TableRow>
                            {['SKU', 'Size', 'FOB', 'Sqm', 'Total FOB', 'Total Sqm', 'V. Qty', 'Length', 'Width', 'UPC'].map((header) => (
                                <TableCell
                                    key={header}
                                    className='table_header'
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((order, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    className='table_cell'
                                >
                                    {order.sku ? (
                                        <a
                                            href={order.name}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className='table_cell_link'
                                        >
                                            {order.sku}
                                        </a>
                                    ) : 'N/A'}
                                </TableCell>
                                <TableCell className='table_cell'>{order.size || 'N/A'}</TableCell>
                                <TableCell className='table_cell'>{Number(order.FOB).toFixed(2)}</TableCell>
                                <TableCell className='table_cell'>{Number(order.SQMeterPerPiece).toFixed(2)}</TableCell>
                                <TableCell className='table_cell'>{Number(order.totalFOB).toFixed(2)}</TableCell>
                                <TableCell className='table_cell'>{Number(order.totalSqm).toFixed(2)}</TableCell>
                                <TableCell className='table_cell'>{order.validatedQty}</TableCell>
                                <TableCell className='table_cell'>{order.length || 'N/A'}</TableCell>
                                <TableCell className='table_cell'>{order.width || 'N/A'}</TableCell>
                                <TableCell className='table_cell_last'>{order.upc || 'N/A'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

PoInvoiceTemplate.propTypes = {
    selectedPdfType: PropTypes.string.isRequired,
    purchaseOrderDetail: PropTypes.object.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PoInvoiceTemplate