import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './SortFilter.css'

import SortDropdown from 'components/uiElements/sort/SortDropdown'

const SortFilter = (props) => {
  const [sortOn, setSortOn] = useState(props.selectedFilter || 'createdOn')
  const [sortOrder, setSortOrder] = useState(1)
  function compareValues(key, order) {
    return function innerSort(a, b) {
      if (
        !Object.prototype.hasOwnProperty.call(a, key) ||
        !Object.prototype.hasOwnProperty.call(b, key)
      ) {
        // property doesn't exist on either object 
        return 0
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

      let comparison = 0
      if (varA > varB) {
        comparison = 1
      } else if (varA < varB) {
        comparison = -1
      }
      return order === -1 ? comparison * -1 : comparison
    }
  }

  const stateUpdate = (key, order = 'asc') => {
    props.setItems((prevState) => {
      const newState = [...prevState]
      return newState.sort(compareValues(key, order))
    })
  }

  React.useEffect(() => {
    stateUpdate(sortOn, sortOrder)
  }, [sortOn, sortOrder])

  return (
    <>
      <div>
        <SortDropdown
          refetch={props.refetch}
          setSortOn={setSortOn}
          sortOn={sortOn}
          items={props.sortOnData}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          productView={props.productView}
          setProductView={props.setProductView}
          setSearchProductValue={props.setSearchProductValue}

        />
      </div>
    </>
  )
}

SortFilter.propTypes = {
  items: PropTypes.object,
  setItems: PropTypes.func,
  selectedFilter: PropTypes.string,
  sortOnData: PropTypes.array,
  setProductView: PropTypes.func,
  productView: PropTypes.number,
  refetch: PropTypes.func,
  setSearchProductValue: PropTypes.func,
}

export default SortFilter
