const endpoints = {

  // Auth service endpoints
  SIGNUP: '/signup',
  VENDOR_SIGNUP: '/vendorsignup',
  RETAIL_SHOP_SIGNUP: '/retailShopSignup',
  PAYMENT: '/payment',
  AUTHENTICATED_PAYMENT: '/payment/auth',
  PAYMENT_INFO: '/payment/info',
  LOGIN: '/login',
  VERIFY_OTP: '/verifyOtp',
  RESEND_OTP: '/resendOtp',
  LOGOUT: '/logout',
  UPDATE_PASSWORD: '/updatepassword',
  UPDATE_EMAIL: '/updateemail',
  GET_USER_COMPANY: '/getusercompany',
  RESET_EMAIL: '/resetEmail',
  USER_PROFILE: '/userprofile',
  UPDATE_USER: '/updateuser',
  USER_SETTINGS: '/usersettings',
  GET_COMPANY_USERS: (isActive) => `/getcompanyusers/${4}?isActive=${isActive}`,
  UPDATE_USER_DETAILS: (userId) => `/updateUserDetails/${userId}`,
  SHOP_NAME: '/shopName',
  GET_EMAIL_VERIFY_TOKEN: '/getemailverifytoken',
  POST_EMAIL_VERIFY_TOKEN: '/postemailverifytoken',
  UPDATE_COMPANY: '/updatecompany',
  INVITE_EMAIL: '/inviteEmail',
  GET_CURRENT_USER: '/getcurrentuser',
  IS_LOGGED_IN: '/isloggedin',
  REFRESH_TOKEN: (companyId, shop_name) => `/refreshToken/${companyId}/${shop_name}`,
  GET_VENDORS: (id) => `/vendors/${id}`,
  GET_RETAIL_SHOP: (id) => `/getRetailShop/${id}`,

  // Product endpoints
  ADD_PRODUCT: '/addproduct',
  GET_ORDERS_DATA: '/get_orders',
  GET_ORDER_DETAILS: (orderId) => `/get_orders?order_id=${orderId}`,
  GET_STOCKS_DATA: '/get_inventory_data',
  CREATE_ORDERS_DATA: '/create_order',
  ADD_BULK_PRODUCTS: '/importbulk',
  GET_PRODUCT_BY_SKU: (Sku) => `/productbysku/${Sku}`,
  GET_PRODUCTS_BY_DESIGN: (companyId, design) => `/product/byDesign/${companyId}/${design}`,
  SEARCH_PRODUCT: (companyId, searchText, productsPerPage, page) => `/search/${companyId}/${searchText}/${productsPerPage}/${page}`,
  FILTER_PRODUCT: (companyId, design, vendorIds, colors, productsPerPage, page) => `/filter/${companyId}/${productsPerPage}/${page}?design=${design}&&vendorIds=${vendorIds}&&colors=${colors}`,
  GET_PRODUCT_BY_SHOP_NAME: (shopName) => `/shop/${shopName}`,
  DELETE_PRODUCT: (productId) => `/product/${productId}`,
  UPDATE_PRODUCT: (id) => `/Product/${id}`,
  UPDATE_PRODUCT_BY_SKU: (sku) => `/product/sku/${sku}`,
  GET_COMPANY_PRODUCTS: (companyId, productsPerPage, page) => `/Product/company/${companyId}/${productsPerPage}/${page}`,
  GET_COMPANY_PRODUCTS_BY_DESIGN: (companyId, search, productsPerPage, page) => `/productbyDesign/${companyId}/${search}/${productsPerPage}/${page}`,
  GET_USER_PRODUCTS: (userId, productsPerPage, page) => `/foruser/${userId}/${productsPerPage}/${page}`,
  GET_PRODUCTS_BY_COMPANY: (companyId) => `/product/company/${companyId}`,
  GET_USER_PRODUCTS_PAGE: (userId) => `/Product/foruser/${userId}`,
  GET_PRODUCTS_BY_VENDOR: (vendorId, productsPerPage, page) => `/product/vendor/${vendorId}/${productsPerPage}/${page}`,
  GET_METADATA: (companyId) => `/metadata/${companyId}`,
  UPDATE_METADATA: (companyId) => `/updateproduct/${companyId}`,
  GET_PRODUCT_BY_ID: (id) => `/product/${id}`,
  GET_PRODUCT_BY_NAME: (companyId) => `/getcompprodcount?companyId=${companyId}`,
  GET_PRODUCT_STATUS: (companyId) => `/getproductStatus?companyId=${companyId}`,
  DELETE_MULTIPLE_PRODUCTS: (ids) => `/multiple?productIds=${ids}`,
  GET_PRODUCTS_DATA: (productId) => `/getData?productIds=${productId}`,
  UPDATE_MULTIPLE_PRODUCTS: (productIds) => `/updateProducts?id=${productIds}`,
  GET_PRODUCT_DESIGNS: (companyId) => `/design/${companyId}`,

  // Purchase Order endpoints

  ADD_PURCHASE_ORDER: '/purchaseorder',
  SEND_TEXT_MESSAGE: '/createChat',
  CREATE_INITIAL_DELIVERY: '/delivery',
  ADD_PROJECT: '/createprojects',
  CREATE_PROJECT_DELIVERY: `/createprojectDelivery`,
  CREATE_BACKORDER: `/backOrder`,

  GET_COMPANY_PURCHASE_ORDER: (companyId) => `/getcompanypurchaseorder/${companyId}`,
  FILTER_PURCHASE_ORDER: (userType, companyId, filterProductId, po, shipVia, shipTo, supplier, order_deadline, poDueDate) => {
    const productId = filterProductId?.join('%2C')
    const baseUrl = process.env.REACT_APP_BASE_URL
    const companyIdQueryParam = companyId ? `companyId=${companyId}` : ''
    const userTypeParam = `userType=${userType}`
    const productIdQueryParam = productId ? `productId=${productId}` : ''
    const poQueryParam = po == 'null' ? '' : `status=${po}`
    const shipViaQueryParam = shipVia == 'null' ? '' : `shipVia=${shipVia}`
    const shipToQueryParam = shipTo == 'null' ? '' : `shipTo=${shipTo}`
    const supplierQueryParam = supplier == 'null' ? '' : `supplierName=${supplier}`
    const startQueryParam = order_deadline == 'null' ? '' : `order_deadline=${order_deadline}`
    const endQueryParam = poDueDate == 'null' ? '' : `poDueDate=${poDueDate}`
    const queryParams = [
      companyIdQueryParam,
      productIdQueryParam,
      poQueryParam,
      supplierQueryParam,
      shipToQueryParam,
      shipViaQueryParam,
      startQueryParam,
      userTypeParam,
      endQueryParam
    ]
    const filteredQueryParams = queryParams.filter((param) => param !== '')
    const dynamicUrl = `${baseUrl}/filterpurchaseorder/${companyIdQueryParam}/${filteredQueryParams.length > 0 ? `?${filteredQueryParams.join('&')}` : ''}`
    // const dynamicUrl = `http://localhost:5006/api/purchaseorder/filter/${companyIdQueryParam}/${filteredQueryParams.length > 0 ? `?${filteredQueryParams.join('&')}` : ''}`
    return dynamicUrl
  },
  GET_PURCHASE_ORDER_IDS: (companyId, type, userId) => `/poid/${companyId}/${type}/${userId}`,
  GET_SINGLE_PURCHASE_ORDER: (PoNumber) => `/getpurchaseorder/${PoNumber}`,
  GET_SUPPLIER_PURCHASE_ORDER: (supplierId) => `/supplier/${supplierId}`,
  GET_PRODUCTS_ON_IDS: (ids) => `/productbyids?ids=${ids}`,
  UPDATE_PO_SKU_PROPOSED_QTY: (poId, productId) => `/po/proposeqty/${poId}/${productId}`,
  UPDATE_PO_SKU_VALIDATE_QTY: (poId) => `/po/validateqty/${poId}`,
  GET_ALL_CHATS: (role, companyId, userId) => `/chats/${role}/${companyId}/${userId}`,
  GET_ALL_PO_CHATS: (poId, page, limit) => `/pochats/${poId}/${limit}/${page}`,
  GET_ALL_UNREAD_CHATS: (userId) => `/unreadChats/${userId}`,
  GET_ALL_QUERY_CHATS: (orderId, page, limit) => `/queryChats/${orderId}/${limit}/${page}`,
  GET_PO_ON_IDS: (ids) => `/getpurchaseorders/id?id=${ids}`,
  DELETE_PURCHASE_ORDER: (poNumber) => `/purchaseorder/${poNumber}`,
  UPDATE_PURCHASE_ORDER: (PoNumber) => `/purchaseorder/${PoNumber}`,
  UPDATE_IS_DISPATCH: (id) => `/api/purchaseorder/${id}`,
  GET_DELIVERIES_ON_PO_NUMBER: (poNumber) => `/delivery/po/number/${poNumber}`,
  GET_SINGLE_DELIVERY_ON_DELIVERY_ID: (deliveryId) => `/delivery/${deliveryId}`,
  GET_DELIVERY_ON_IDS: (ids) => `/getDelivery/ids?id=${ids}`,
  UPDATE_DELIVERY: (deliveryId) => `/delivery/sku/${deliveryId}`,
  UPDATE_DELIVERY_STATUS: (deliveryId) => `/getDeliveryId/status?deliveryId=${deliveryId}`,
  UPDATE_PURCHASE_ORDER_STATUS: (poNumber) => `/po/status/${poNumber}`,
  GET_PURCHASE_DELIVERY_ON_PO_NUMBER: (poNumber) => `/getDelivery/poNumbers?poNumber=${poNumber}`,
  FILTER_PROJECT: (companyId, projectId, bookingNumber, container, freightLine, projectStage, loadDate, arrivalDate, deliveryDate, warehouse) => {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const companyIdQueryParam = companyId ? `companyId=${companyId}` : ''
    const poQueryParam = projectId == 'null' ? '' : `projectId=${projectId}`
    const bookingNumberQueryParam = bookingNumber == 'null' ? '' : `bookingNumber=${bookingNumber}`
    const containerQueryParam = container == 'null' ? '' : `container=${container}`
    const freightLineQueryParam = freightLine == 'null' ? '' : `freightLine=${freightLine}`
    const stageQueryParam = projectStage == 'null' ? '' : `projectStage=${projectStage}`
    const startQueryParam = loadDate == 'null' ? '' : `loadDate=${loadDate}`
    const endQueryParam = arrivalDate == 'null' ? '' : `arrivalDate=${arrivalDate}`
    const deliveryDateQueryParam = deliveryDate == 'null' ? '' : `deliveryDate=${deliveryDate}`
    const warehouseQueryParam = warehouse == 'null' ? '' : `warehouse=${warehouse}`
    const queryParams = [
      companyIdQueryParam,
      poQueryParam,
      stageQueryParam,
      freightLineQueryParam,
      containerQueryParam,
      bookingNumberQueryParam,
      startQueryParam,
      endQueryParam,
      deliveryDateQueryParam,
      warehouseQueryParam
    ]
    const filteredQueryParams = queryParams.filter((param) => param !== '')
    return `${baseUrl}/search/${companyId}/${filteredQueryParams.length > 0 ? `?${filteredQueryParams.join('&')}` : ''}`
  },
  GET_ALL_PROJECTS_ON_COMPANY_ID: (companyId) => `/getproject/${companyId}`,
  GET_PROJECT_BY_PROJECT_ID: (projectId) => `/project/${projectId}`,
  DELETE_PROJECT_BY_PROJECT_ID: (projectId) => `/project/${projectId}`,
  UPDATE_PROJECT_BY_PROJECT_ID: (projectId) => `/project/${projectId}`,
  GET_DELIVERY_BY_PROJECT_ID: (projectId) => `/getprojectdelivery/${projectId}`,
  GET_SINGLE_DELIVERY_ID: (deliveryId) => `/projectDelivery/${deliveryId}`,
  UPDATE_PROJECT_DELIVERY_STATUS: (deliveryId) => `/delivery/status/${deliveryId}`,
  UPDATE_PO_IN_DELIVERY: (deliveryId) => `/delivery/po/${deliveryId}`,

  // Sales Dashboard endpoints
  GET_SALES_DATA: (startDate, endDate, page, limit, comparison, sortType, sortOrder, shop, country, warehouse, shop_type, sku) => `/salesData?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&comparison=${comparison}&sortType=${sortType}&sortOrder=${sortOrder}&shop=${shop}&country=${country}&warehouse=${warehouse}&shop_type=${shop_type}&sku=${sku}`,
  GET_PIE_CHART_DATA: (startDate, endDate, comparison, shop, country, warehouse, shop_type, sku) => `/pieChartData?startDate=${startDate}&endDate=${endDate}&comparison=${comparison}&shop=${shop}&country=${country}&warehouse=${warehouse}&shop_type=${shop_type}&sku=${sku}`,
  GET_WAREHOUSE_DATA: `/getWarehouseData`,
  GET_SHIPMENT_DATA: `/getShipmentData`,
  GET_SHOP_DATA: `/getShopData`,
  GET_SUPPLIER_DATA: `/supplier_listing`,
  GET_ALL_SALES_DATA: `/getAllSalesData`,
  GET_SALES_DATA_YEARS: (dateObj) => `/salesDataYears?dateObj=${dateObj}`,
  GET_GRAPH_DATA: (queryParams) => `/graphData?${queryParams.toString()}`,
  GET_SALES_LISTING: (query) => `/sales_report_listing${query ? `?${query}` : ""}`,
  DOWNLOAD_CONTENT: (query) => `/get_purchase_order_report${query ? `?${query}` : ""}`,
  GET_TOTAL_REVENUE: (query) => `/totalRevenue${query ? `?${query}` : ""}`,

  // TRENDS MODULE API
  GET_SALES_TREND_LISTING: (query) => `/sales_trend_listing${query ? `?${query}` : ""}`,
  GET_STOCK_ALERT_LISTING: (query) => `/stock_alert_listing${query ? `?${query}` : ""}`,

  // Advertisement
  ADD_ADVERTISEMENT_DATA: '/create',
  GET_ADVERTISEMENT_REPORT: (query) => `/advertisement${query ? `?${query}` : ""}`,
  GET_LAST_IMPORTED_DATE: (query) => `/lastUpdatedDate${query ? `?${query}` : ""}`,

  GET_TOTAL_SALES_DATA: (startDate, endDate, presetValue, groupDataValue, fromDate, toDate, comparison, shop, country, warehouse, shop_type, sku) =>
    `/salesDataTotal?startDate=${startDate}&endDate=${endDate}&presetValue=${presetValue}&groupDataValue=${groupDataValue}&fromDate=${fromDate}&toDate=${toDate}&comparison=${comparison}&shop=${shop}&country=${country}&warehouse=${warehouse}&shop_type=${shop_type}&sku=${sku}`,
  GET_SALES_REPORT_BY_SHOP: (query) => `/sales_report_by_shop${query ? `?${query}` : ""}`,
  GET_FILTER_LIST_ADVERTISEMENT: (query) => `/adgroups${query ? `?${query}` : ""}`,
  ADD_REFUND_DATA: '/createrefund',
  GET_REFUND_DATA: (query) => `/list${query ? `?${query}` : ""}`,
  GET_MARKETPLACE_DATA: '/marketplace',
  GET_COUNTRY_DATA: (orderNumber) => `/ordercountry/${orderNumber}`,
  GET_ALL_ADVERTISEMENT_RAW: (page) => `/allAdvertisementData?page=${page}`
}

export { endpoints }
