import React, { } from 'react'
import { CSidebar, CSidebarNav, CSidebarBrand } from '@coreui/react'
import SimpleBar from 'simplebar-react'
import PropTypes from 'prop-types'
import { AppSidebarNav } from './AppSidebarNav'
import navigation from 'routes/SidebarNav'
import Icon from 'assets/images/small-logo.png'
import image from 'assets/images/large-logo.png'
import 'simplebar/dist/simplebar.min.css'

const AppSidebar = (props) => {
  // const [narrowShow, setNarrowShow] = useState(true)
  return (
    <CSidebar
      position='fixed'
      // visible={props.show}
      narrow={props.show}
    // onVisibleChange={(visible) => {
    //   props.setShow(visible)
    // }}
    // onMouseEnter={(e) => {
    //   e.preventDefault()
    //   setNarrowShow(false)
    // }}
    // onMouseLeave={(e) => {
    //   e.preventDefault()
    //   setNarrowShow(true)
    // }}
    >
      <CSidebarBrand className='d-none d-md-flex' to='/'>
        <img
          src={image}
          height='90%'
          width='90%'
          alt='POM'
          className='sidebar-brand-full'
        />
        <img src={Icon} height={50} alt='POM' className='sidebar-brand-narrow' />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
    </CSidebar>
  )
}

AppSidebar.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func
}

export default React.memo(AppSidebar)
