import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react'
import { Grid, TableRow, TableCell, Table, TableHead, TableBody, TableContainer, CircularProgress, Tooltip, FormControl, TextField, Select, MenuItem, Button } from '@mui/material'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'
import { useAdvertisementHooks } from './useAdvertisementHooks'
import CustomisedInput from 'components/formElements/CustomisedInput'
import SkeletonRow from 'components/common/SkeletonRow'

const AdvertisementRawData = () => {
    const {
        allAdvertisementData,
        loadMoreAdvertisementData,
        rawloading,
        buttonLoader,
        shop,
        filteredShipment,
        adGroups,
        setSkuPage,
        applyFilters,
        selectedShopRaw,
        selectedShipmentCountryRaw,
        selectedAdGroupRaw,
        setSelectedAdGroupRaw,
        setSelectedShipmentCountryRaw,
        setSelectedShopRaw,
        setProductSkuRaw,
        resetFilters,
        startDateRaw,
        setstartDateRaw,
        endDateRaw,
        setendDateRaw,
    } = useAdvertisementHooks()

    const [sortConfig, setSortConfig] = useState({ type: 'spend', order: 'asc' })
    const tableContainerRef = useRef(null)

    const sortData = (AdvertisementListData, type, order) => {
        return [...AdvertisementListData].sort((key1, key2) => {
            let Key1Value = key1.metrics[type.toLowerCase()]
            let Key2Value = key2.metrics[type.toLowerCase()]

            if (type.toLowerCase() === 'acos') {
                Key1Value = (key1.metrics.spend / key1.metrics.revenue) * 100
                Key2Value = (key2.metrics.spend / key2.metrics.revenue) * 100
                if (!isFinite(Key1Value)) Key1Value = 0
                if (!isFinite(Key2Value)) Key2Value = 0
            }
            if (Key1Value === undefined || Key1Value === null) Key1Value = 0
            if (Key2Value === undefined || Key2Value === null) Key2Value = 0
            return order === 'asc' ? Key1Value - Key2Value : Key2Value - Key1Value
        })
    }

    const sortedData = useMemo(
        () => sortData([...allAdvertisementData], sortConfig.type, sortConfig.order),
        [allAdvertisementData, sortConfig]
    )

    const getIconStyle = (type, order) => ({
        color: sortConfig.type === type && sortConfig.order === order ? 'blue' : 'grey',
        cursor: 'pointer',
        marginLeft: '1px',
    })

    const handleSort = async (type) => {
        const container = tableContainerRef.current
        if (container) {
            await container.scrollTo({ top: 0, behavior: 'smooth' })
        }
        const newOrder = sortConfig.type === type && sortConfig.order === 'asc' ? 'desc' : 'asc'
        setSortConfig({ type, order: newOrder })
    }

    const handleScroll = useCallback(() => {
        const container = tableContainerRef.current
        if (container) {
            if (container.scrollTop + container.clientHeight >= container.scrollHeight - 50) {
                loadMoreAdvertisementData()
            }
        }
    }, [loadMoreAdvertisementData])

    useEffect(() => {
        const container = tableContainerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll)
            return () => container.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll])

    return (
        <section className='page-spacing'>
            <div className='container-fluid'>
                <div className='common-card rounded-3'>
                    <Grid className='p-2 ms-2' item xs={12} sm={6} md={4} lg={1}>
                        <Grid>
                            <h3 className='fw-bold advertisement-report'>
                                Advertisement Raw Data <span></span>
                            </h3>
                        </Grid>
                        {/* FILTER INPUTS */}
                        <Grid container spacing={2} sx={{ marginTop: '30px', marginBottom: '30px' }}>
                            <Grid item xs={12} sm={6} md={4} lg={1}>
                                <FormControl
                                    fullWidth
                                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                                    size='small'
                                    className='border-none'
                                >
                                    <Select
                                        value={selectedShopRaw}
                                        displayEmpty
                                        InputLabelProps={{ shrink: false }}
                                        renderValue={(selected) => {
                                            if (selected?.length === 0) {
                                                return `Shops`
                                            }
                                            return selected
                                        }}
                                        onChange={(e) => {
                                            setSelectedShopRaw(e.target.value)
                                        }}
                                        IconComponent={() => null}
                                    >
                                        <MenuItem value=''>All Shops</MenuItem>
                                        {shop.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={1}>
                                <FormControl
                                    fullWidth
                                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                                    size='small'
                                    className='border-none'
                                >
                                    <Select
                                        value={selectedShipmentCountryRaw}
                                        displayEmpty
                                        InputLabelProps={{ shrink: false }}
                                        renderValue={(selected) => {
                                            if (selected?.length === 0) {
                                                return `Shipment Country`
                                            }
                                            return selected
                                        }}
                                        IconComponent={() => null}
                                        onChange={(e) => {
                                            setSelectedShipmentCountryRaw(e.target.value)
                                        }}
                                    >
                                        <MenuItem value=''>All Country</MenuItem>
                                        {filteredShipment?.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={1}>
                                <Tooltip title={'Sku or Design'}>
                                    <FormControl
                                        fullWidth
                                        sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                                        size='small'
                                        className='border-none'
                                    >
                                        <TextField
                                            placeholder='Sku or Design'
                                            size='small'
                                            variant='outlined'
                                            InputLabelProps={{
                                                shrink: false
                                            }}
                                            input={<CustomisedInput />}
                                            onChange={(e) => {
                                                setProductSkuRaw(e.target.value.trim()), setSkuPage(0)
                                            }}
                                        />
                                    </FormControl>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={1}>
                                <FormControl
                                    fullWidth
                                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                                    size='small'
                                    className='border-none'
                                >
                                    <Select
                                        value={selectedAdGroupRaw}
                                        displayEmpty
                                        InputLabelProps={{ shrink: false }}
                                        renderValue={(selected) => {
                                            if (selected?.length === 0) {
                                                return `Ad Groups`
                                            }
                                            return selected
                                        }}
                                        IconComponent={() => null}
                                        onChange={(e) => {
                                            setSelectedAdGroupRaw(e.target.value)
                                        }}
                                    >
                                        <MenuItem value=''>Ad Groups</MenuItem>
                                        {adGroups?.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={1}>
                                <FormControl
                                    fullWidth
                                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                                    size='small'
                                    className='border-none'
                                >
                                    <TextField
                                        type='date'
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        IconComponent={() => null}
                                        value={startDateRaw || ''}
                                        onChange={(e) => setstartDateRaw(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={1}>
                                <FormControl
                                    fullWidth
                                    sx={{ backgroundColor: '#F9F9F9', borderColor: '#D3D3D3' }}
                                    size='small'
                                    className='border-none'
                                >
                                    <TextField
                                        type='date'
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        IconComponent={() => null}
                                        value={endDateRaw || ''}
                                        disabled={!startDateRaw}
                                        onChange={(e) => setendDateRaw(e.target.value)} // Update endDate directly
                                        min={startDateRaw || ''}
                                        max={new Date().toISOString().split('T')[0]}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip size={'2rem'} title={buttonLoader ? `Please wait while we fetching the records` : ``}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={applyFilters}
                                        sx={{
                                            backgroundColor: '#151718',
                                            '&:hover': {
                                                backgroundColor: '#151718',
                                            },
                                            textTransform: 'none',
                                            '@media (max-width: 600px)': {
                                                fontSize: '0.8rem',
                                            },
                                        }}
                                    >
                                        {buttonLoader ? <CircularProgress size={20} style={{ color: 'white' }} /> : `Apply`}
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={resetFilters}
                                    sx={{
                                        backgroundColor: '#151718',
                                        '&:hover': {
                                            backgroundColor: '#151718',
                                        },
                                        textTransform: 'none',
                                        '@media (max-width: 600px)': {
                                            fontSize: '0.8rem',
                                        },
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <div className='row mt-4'>
                    <div className='col-md-12 h-100'>
                        <div className='common-card rounded-3'>
                            <div className='common-card-title'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5>Advertisement Raw Data Listing</h5>
                                </div>
                            </div>
                            <div className='common-card-content border-0 mt-0 mb-0 pt-0'>
                                <Grid item xs={12} lg={12} md={12} sm={12} id={'page-scroll'} className='p-4'>
                                    <TableContainer className='scroll-box' ref={tableContainerRef}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}><strong>Shop</strong></TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}><strong>Country</strong></TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}><strong>SKU</strong></TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}><strong>Date</strong></TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                        <strong onClick={() => handleSort('impressions')}>Impressions
                                                            <FaArrowUpLong style={getIconStyle('impressions', 'asc')} />
                                                            <FaArrowDownLong className='ms-0' style={getIconStyle('impressions', 'desc')} />
                                                        </strong>
                                                    </TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                        <strong onClick={() => handleSort('clicks')}>Clicks
                                                            <FaArrowUpLong style={getIconStyle('clicks', 'asc')} />
                                                            <FaArrowDownLong className='ms-0' style={getIconStyle('clicks', 'desc')} />
                                                        </strong>
                                                    </TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                        <strong onClick={() => handleSort('avgcpc')}>Avg CPC
                                                            <FaArrowUpLong style={getIconStyle('avgcpc', 'asc')} />
                                                            <FaArrowDownLong className='ms-0' style={getIconStyle('avgcpc', 'desc')} />
                                                        </strong>
                                                    </TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                        <strong onClick={() => handleSort('conversions')}>Conversions
                                                            <FaArrowUpLong style={getIconStyle('conversions', 'asc')} />
                                                            <FaArrowDownLong className='ms-0' style={getIconStyle('conversions', 'desc')} />
                                                        </strong>
                                                    </TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                        <strong onClick={() => handleSort('revenue')}>Revenue
                                                            <FaArrowUpLong style={getIconStyle('revenue', 'asc')} />
                                                            <FaArrowDownLong className='ms-0' style={getIconStyle('revenue', 'desc')} />
                                                        </strong>
                                                    </TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                                                        <strong onClick={() => handleSort('spend')}>Spend
                                                            <FaArrowUpLong style={getIconStyle('spend', 'asc')} />
                                                            <FaArrowDownLong className='ms-0' style={getIconStyle('spend', 'desc')} />
                                                        </strong>
                                                    </TableCell>
                                                    <TableCell style={{ whiteSpace: 'nowrap' }}><strong>Ad Group</strong></TableCell>
                                                    {/* <TableCell>
                                            <strong onClick={() => handleSort('acos')}>Acos
                                                <FaArrowUpLong style={getIconStyle('acos', 'asc')} />
                                                <FaArrowDownLong className='ms-0' style={getIconStyle('acos', 'desc')} />
                                            </strong>
                                        </TableCell> */}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rawloading ? (Array.from({ length: 10 }, (_, index) => <SkeletonRow key={index} cols={11} />))
                                                    : (Array.isArray(sortedData) && sortedData.length ? (
                                                        sortedData.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.shop || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.country || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.sku[0] || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{new Date(row?.date).toLocaleDateString() || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.metrics?.impressions || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.metrics?.clicks || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.metrics?.avgcpc || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.metrics?.conversions || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.metrics?.revenue || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.metrics?.spend || '-'}</TableCell>
                                                                <TableCell style={{ whiteSpace: 'nowrap' }}>{row?.adgroup || '-'}</TableCell>
                                                                {/* <TableCell>{(row.metrics.spend && row.metrics.revenue && row.metrics.revenue !== 0) ? ((row.metrics.spend / row.metrics.revenue) * 100).toFixed(2) : '--'}</TableCell> */}
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={11} align='center'>
                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30, marginBottom: 30 }}>
                                                                    <p className='text-center'>No Records Found...</p>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                {rawloading && (
                                                    <TableRow>
                                                        <TableCell colSpan={14} style={{ textAlign: 'center', padding: '50px' }}>
                                                            <CircularProgress />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AdvertisementRawData