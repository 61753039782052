import React, { Fragment, useContext } from 'react'
import { CCol, CRow, CFormLabel, CFormInput, CFormSelect, CForm } from '@coreui/react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { UserContext } from 'context/AuthContext/UserContext'

function PoHeader(props) {
  const param = useParams()
  const ShipVia = ['Vessel', 'Truck']
  const {userData} = useContext(UserContext)
  return (
    <Fragment>
      <CForm className='row g-3 needs-validation' noValidate validated={props.validated} onSubmit={props.handleSubmit}>
        <CRow className='ms-2 mt-5'>
          <CCol>
            <CRow>
              <CCol sm={4}>
                <CFormLabel htmlFor='type' className='col-sm-4 col-form-label' data-testid='Supplier'>
                  Supplier<span style={{ color: "red" }}>*</span>
                </CFormLabel>
                <CFormSelect
                  id='supplier'
                  datatestid='Supplier'
                  onChange={props.handleChange('supplier')}
                  value={JSON.stringify({ key: props?.values?.supplierName, value: props?.values?.supplier,supplierCompany: props?.values?.supplierCompany })}
                  feedbackValid=''
                  feedbackInvalid='Please choose supplier'
                  required
                  disabled={param.poNumber ? true : false}
                >
                  <option selected='hello' disabled='' value=''>
                    Choose...
                  </option>
                  {props.vendors?.map((option) => (
                     <option
                     key={option.id}
                     value={JSON.stringify({ key: option.companyName, value: option.id ,supplierCompany:option.companyId})}
                     name={option.companyName}
                     data-email={option.email} 
                     data-fname={option.fName}
                   >
                      {option.companyName}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
              <CCol sm={4}>
                <CFormLabel htmlFor='name' className='col-sm-4 col-form-label'>
                  PO Number<span style={{ color: "red" }}>*</span>
                </CFormLabel>
                <CFormInput
                  type='text'
                  id='PoNumber'
                  datatestid='PoNumber'
                  placeholder='Enter PO Number'
                  onChange={props.handleChange('PoNumber')}
                  value={props?.values?.PoNumber}
                  feedbackValid=''
                  feedbackInvalid='Please Enter PO Number.'
                  required
                  disabled={param.poNumber ? true : false}
                />
              </CCol>
              <CCol sm={4}>
                <CFormLabel htmlFor='name' className='col-sm-4 col-form-label'>
                  PO Date
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='PoDate'
                  onChange={props.handleChange('PoDate')}
                  value={props?.values?.PoDate}
                  min={new Date().toJSON().slice(0, 10)}
                  feedbackValid=''
                  feedbackInvalid='Please select Po Date'
                  required
                  disabled={param.poNumber ? true : false}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm={4}>
                <CFormLabel htmlFor='type' className='col-sm-4 col-form-label' data-testid='Supplier'>
                  Ship Via
                </CFormLabel>
                <CFormSelect
                  id='shipVia'
                  datatestid='shipVia'
                  onChange={props.handleChange('shipVia')}
                  feedbackValid=''
                  feedbackInvalid='Please choose Ship Via'
                  value={props?.values?.shipVia}
                  required
                  disabled={userData?.user?.pomUserType !== 1}
                >
                  <option selected='' disabled='' value=''>
                    Choose...
                  </option>
                  {ShipVia.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>
              <CCol sm={4}>
                <CFormLabel htmlFor='type' className='col-sm-4 col-form-label' data-testid='Supplier'>
                  Ship To
                </CFormLabel>
                <CFormSelect
                  id='shipTo'
                  datatestid='shipTo'
                  onChange={props.handleChange('shipTo')}
                  feedbackValid=''
                  feedbackInvalid='Please Choose ship to '
                  value={props?.values?.shipTo}
                  required
                  disabled={userData?.user?.pomUserType !== 1}

                >
                  <option selected='' disabled='' value=''>
                    Choose...
                  </option>
                  {props?.warehouseName?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </CFormSelect>
              </CCol>

              <CCol sm={4}>
                <CFormLabel htmlFor='name' className='col-sm-4 col-form-label'>
                  PO Load Date
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='PoLoadDate'
                  placeholder='Input the PO Number'
                  onChange={props.handleChange('PoLoadDate')}
                  value={props?.values?.PoLoadDate}
                  min={props?.values?.PoDate}
                  feedbackValid=''
                  feedbackInvalid='Please select Po Load Date'
                  required
                  disabled={userData?.user?.pomUserType !== 1}

                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm={4}>
                <CFormLabel htmlFor='name' className='col-sm-4 col-form-label'>
                  PO Due Date
                </CFormLabel>
                <CFormInput
                  type='date'
                  id='PoDueDate'
                  onChange={props.handleChange('PoDueDate')}
                  value={props?.values?.PoDueDate}
                  min={props?.values?.PoDate}
                  feedbackValid=''
                  feedbackInvalid='Please select Due Date'
                  disabled={userData?.user?.pomUserType !== 1}

                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CForm>
    </Fragment>
  )
}

export default PoHeader

PoHeader.propTypes = {
  handleDropdownChange: PropTypes.func,
  productCategory: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  productValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subCategory: PropTypes.array,
  subCategoryValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleChange: PropTypes.func,
  handleSwitch: PropTypes.func,
  handleSubmit: PropTypes.func,
  validated: PropTypes.bool,
  values: PropTypes.object,
  vendors: PropTypes.array,
  isCShop: PropTypes.bool,
  isValidated: PropTypes.bool,
  supplier: PropTypes.string,
  editPoPath: PropTypes.string,
  warehouseName: PropTypes.array
}
