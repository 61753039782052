import React, { useContext, useState } from 'react'
import { Tooltip, TextField, Grid, FormControl, TablePagination, Skeleton } from '@mui/material'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import CustomisedInput from 'components/formElements/CustomisedInput'
import { Button, Button as ProductButton, MenuItem, InputLabel, Select } from '@mui/material'
import Modal from 'components/uiElements/modal/Modal'
import { UserContext } from 'context/AuthContext/UserContext'
import { GrPowerReset } from 'react-icons/gr'

const TableToolbar = (props) => {
  const location = useLocation()
  const [openModel, setOpenModel] = useState(false)
  const { loading, setLoading } = useContext(UserContext)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { setSearchKey, setBookSearchKey, setContainerSearchKey, setFreightSearchKey, stage, loadDate, arrivalDates,
    setArrivalDate, deliveryDate, setDeliveryDate, setLoadDate, setStage, end, handleFilterChange, start, fetchProject, setWarehouse, warehouse, arrivalWarehouse } = props

  const options = [
    'Waiting',
    'Loading',
    'In Transit',
    'Arrived',
    'Delivered'
  ]
  const statusPo = ['Quotation', 'Proposal', 'Confirmed', 'Partially Shipped', 'Shipped',"Cancelled","Request For Change"]
  const ShipViafilter = ['Vessel', 'Truck']

  function validatedQty(id, productData) {
    const product = productData?.find((product) => product?.productId === id)
    return product?.validatedQty ? Number(product?.validatedQty?.qty ?? 0) : ''
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10))
    setPage(0)
  }

  let timer
  const onSearch = (value, type) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if (type === "Products") {
        props.searcProducthData(value)
        return true
      }
      else if (type === "Po-Number") {
        setSearchKey(value.trim())
      }
      else if (type === "ProjectID") {
        setSearchKey(value)
      }
      else if (type === "Booking Number") {
        setBookSearchKey(value)
      }
      else if (type === "Container Number") {
        setContainerSearchKey(value)
        return true
      }
      else if (type === "Freight_line") {
        setFreightSearchKey(value)
      }
    }, 1000)
  }

  return (
    <>
      <Modal
        open={openModel}
        onClose={() => setOpenModel(false)}
        header={'Search Products'}
        fullWidth
        maxWidth='xl'
        content={<>
          <div className='mt-3 mb-3'>
            <TextField
              label='Search Products'
              id='product'
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus={true}
              input={<CustomisedInput />}
              onChange={(e) => {
                props.setSearch(true)
                onSearch(e.target.value.toLocaleLowerCase(), 'Products')
              }}
            />
          </div>
          <div style={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr className='order1-dataList' style={{ borderBottom: '1px solid #ccc' }}>
                  <th style={{ padding: '8px', textAlign: 'left' }}>PO Number</th>
                  <th style={{ padding: '8px', textAlign: 'left' }}>Image</th>
                  <th style={{ padding: '8px', textAlign: 'left' }}>Sku</th>
                  <th style={{ padding: '8px', textAlign: 'left' }}>Validated Qty</th>
                  <th style={{ padding: '8px', textAlign: 'left' }}>Status</th>
                </tr>
              </thead>
              {loading ? <><tbody>
                {Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index} style={{ marginBottom: '0', width: 100 }}>
                    <td colSpan="7" style={{ padding: '8px', textAlign: 'left' }}>
                      <Skeleton animation="wave" height={50} />
                    </td>
                  </tr>

                ))}
              </tbody></> :
                <tbody>
                  {props?.productDetails?.length ? props?.productDetails?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                    return <tr key={item?.id} style={{ borderBottom: '1px solid #ccc' }}>
                      <td style={{ padding: '8px', textAlign: 'left' }}>{item?.poNumber ? item?.poNumber : '--'}</td>
                      <td style={{ padding: '8px', textAlign: 'left' }}>
                        <Tooltip
                          title={<img src={Array.isArray(item?.products?.images) && item?.products?.images ? item?.products?.images[0] : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`} alt="Product" width="200px" />}
                          arrow
                          placement="right"
                          PopperProps={{ style: { marginLeft: '10px' } }}
                        >
                          <img src={Array.isArray(item?.products?.images) && item?.products?.images ? item?.products?.images[0] : `https://innoage.blob.core.windows.net/images/648b053c91392cac4623e220/668.9231059410128/MicrosoftTeams-image%20%288%29.png`
                          } alt="Product" width="60px" height="60px" />
                        </Tooltip>
                      </td>
                      <td style={{ padding: '8px', textAlign: 'left' }}>{item?.products?.sku ? item?.products?.sku : '-'}</td>
                      <td style={{ padding: '8px', textAlign: 'left' }}>{item?.products ? validatedQty(item?.products?.id, item?.products?.products) : 0}</td>
                      <td style={{ padding: '8px', textAlign: 'left' }}>{item?.status ? item?.status : '--'}</td>
                    </tr>
                  }
                  ) : <tr style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                    <td colSpan="7" style={{ fontSize: 20, padding: "50px", marginTop: "50px", textAlign: "center" }}>No products available please search ...</td>
                  </tr>}
                </tbody>}
            </table>
          </div>

          {props?.productDetails?.length > 9 && (
            <TablePagination
              className='mt-5'
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={props?.productDetails?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              data-testid='table-pagination'
            />
          )}
        </>}
        handleClose={(e) => {
          e.preventDefault()
          props?.setProductDetails([])
          props.setFilterProductId([])
          setLoading(false)
          props.getAllPurchaseRefetch()
          setOpenModel(false)
        }}
      />
      {location?.pathname == '/purchaseorders' ? (
        <>
          <Grid container alignItems="center"
            spacing={2}
            sx={{ marginBottom: '20px' }}
          >
            <Grid item xs={12} sm={6} md={4} lg={1.5}>
              <Tooltip title="Search Purchase Order">
                <FormControl fullWidth>
                  <TextField
                    label="Search Po-Number"
                    size="medium"
                    id="po_id"
                    variant="filled"
                    input={<CustomisedInput />}
                    onChange={(e) =>
                      onSearch(e?.target?.value.toLowerCase(), 'Po-Number')
                    }
                  />
                </FormControl>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1.5}>
              <Tooltip title="Search Products..">
                <FormControl fullWidth>
                  <TextField
                    label="Search Products"
                    size="medium"
                    id="product"
                    variant="filled"
                    input={<CustomisedInput />}
                    onClick={() => setOpenModel(true)}
                  />
                </FormControl>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="demo-simple-select-filled-label">PO Status</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={props.po}
                  onChange={props.handleFilterChange('po')}
                >
                  {statusPo?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="demo-simple-select-filled-label">Ship Via</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={props.shipVia}
                  onChange={props.handleFilterChange('shipVia')}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ShipViafilter?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth variant="filled" className="border-none">
                <InputLabel id="demo-simple-select-filled-label">Warehouse</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={props.warehouse}
                  onChange={props.handleFilterChange('warehouse')}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {arrivalWarehouse?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth variant="filled" className="border-none">
                <InputLabel id="demo-simple-select-filled-label">Supplier</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={props.supplier}
                  onChange={props.handleFilterChange('supplier')}
                >
                  {props?.vendors ? (
                    props?.vendors?.map((option) => (
                      <MenuItem key={option.companyName} value={option.companyName}>
                        {option.companyName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth variant="filled" className="border-none">
                <TextField
                  label="PO Date - From"
                  variant="filled"
                  type="date"
                  value={props.start}
                  onChange={props.handleFilterChange('start')}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={1}>
              <FormControl fullWidth variant="filled">
                <TextField
                  label="PO Date - To"
                  variant="filled"
                  type="date"
                  value={end}
                  onChange={handleFilterChange('end')}
                  disabled={start === 'null'}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={1}>
              <Button
                sx={{ minHeight: 50 }}
                size="large"
                variant="outlined"
                onClick={() => {
                  props.getAllPurchaseRefetch()
                  props.fetchPurchaseOrders()
                  props.setPo('null')
                  props.setSupplier('null')
                  props.setWarehouse('null')
                  props.setStart('null')
                  props.setEnd('null')
                  props.setShipVia('null')
                }}
              >
                Reset
                <span>
                  <GrPowerReset className="reset-icon ms-2" size="1.2rem" />
                </span>
              </Button>
            </Grid>
          </Grid>
        </>)
        : (location.pathname == '/projects' ? (
          <Grid container alignItems="center" spacing={2} sx={{ marginBottom: '20px', '& > *': { marginX: '5px' } }}>
            <Grid item xs={12} sm={6} md={3} lg={1}>
              <Tooltip title="Search Project ID">
                <FormControl size="small" variant="outlined" fullWidth>
                  <TextField
                    label="Search Project ID"
                    size="medium"
                    id="project_id"
                    variant="filled"
                    input={<CustomisedInput />}
                    onChange={(e) => onSearch(e.target.value.toLowerCase(), 'ProjectID')}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1}>
              <Tooltip title="Search Booking Number">
                <FormControl variant="filled" fullWidth>
                  <TextField
                    label="Booking Number"
                    size="medium"
                    variant="filled"
                    id="booking_id"
                    input={<CustomisedInput />}
                    onChange={(e) => onSearch(e.target.value.toLowerCase(), 'Booking Number')}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1}>
              <Tooltip title="Search Container Number">
                <FormControl variant="filled" fullWidth>
                  <TextField
                    label="Container Number"
                    size="medium"
                    id="container_id"
                    variant="filled"
                    input={<CustomisedInput />}
                    onChange={(e) => onSearch(e.target.value.toLowerCase(), 'Container Number')}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1}>
              <Tooltip title="Search Freight Number">
                <FormControl variant="filled" fullWidth>
                  <TextField
                    label="Freight Line"
                    size="medium"
                    variant="filled"
                    id="Freight_id"
                    input={<CustomisedInput />}
                    onChange={(e) =>
                      onSearch(e.target.value.toLowerCase(), 'Freight_line')
                    }
                  />
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="demo-simple-select-filled-label">Stage</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={stage}
                  onChange={(e) => setStage(e.target.value)}
                >
                  {options?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2} lg={1}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="demo-simple-select-filled-label">Warehouse</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={warehouse}
                  onChange={(e) => setWarehouse(e?.target?.value)}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {arrivalWarehouse?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1.5}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Load Date - From"
                  type="date"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                  value={loadDate}
                  onChange={(e) => setLoadDate(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1.5}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Load Date - To"
                  type="date"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                  value={arrivalDates}
                  onChange={(e) => setArrivalDate(e.target.value)}
                  disabled={loadDate === 'null'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1.5}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label="Delivery Date"
                  type="date"
                  size="medium"
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1.2}>
              <ProductButton
                fullWidth
                sx={{ height: 50 }}
                variant="outlined"
                onClick={() => {
                  setWarehouse('')
                  setSearchKey('')
                  setBookSearchKey('')
                  setContainerSearchKey('')
                  setFreightSearchKey('')
                  setStage('')
                  setLoadDate('')
                  setArrivalDate('')
                  setDeliveryDate('')
                  fetchProject()
                }}
              >
                Reset <span><GrPowerReset className="reset-icon ms-2" size="1.2rem" /></span>
              </ProductButton>
            </Grid>
          </Grid>
        ) : (<Grid container direction='row' justifyContent='space-between' alignItems='center' className='my-3'>
          <Grid item>
            <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
              <Grid item>
                <Tooltip title='Search Po Number'>
                  <FormControl sx={{ m: 1, minWidth: 80 }} size='small' variant='outlined'>
                    <TextField
                      label='Search Po Number'
                      size='medium'
                      variant='filled'
                      input={<CustomisedInput />}
                      onChange={(e) => {

                        setSearchKey(e.target.value.toLowerCase())
                      }}
                    />
                  </FormControl>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>)
        )}
    </>
  )
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setSearchKey: PropTypes.func,
  setBookSearchKey: PropTypes.func,
  setContainerSearchKey: PropTypes.func,
  setFreightSearchKey: PropTypes.func,
  setSortOn: PropTypes.func,
  title: PropTypes.string,
  setSortOrder: PropTypes.func,
  sortOrder: PropTypes.number,
  sortOn: PropTypes.string,
  items: PropTypes.array,
  showReviewList: PropTypes.bool,
  setDraftBtn: PropTypes.func,
  draftBtn: PropTypes.bool,
  setPo: PropTypes.func,
  setSupplier: PropTypes.func,
  setWarehouse: PropTypes.func,
  setStart: PropTypes.func,
  setEnd: PropTypes.func,
  setShipVia: PropTypes.func,
  setWarehouse: PropTypes.func,
  setStart: PropTypes.func,
  setEnd: PropTypes.func,
  handleFilterChange: PropTypes.func,
  fetchFilterData: PropTypes.func,
  fetchPurchaseOrders: PropTypes.func,
  supplier: PropTypes.string,
  po: PropTypes.string,
  getAllPurchaseRefetch: PropTypes.func,
  warehouse: PropTypes.string,
  arrivalWarehouse: PropTypes.arrayOf().isRequired,
  shipVia: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  vendors: PropTypes.array,
  stage: PropTypes.string,
  loadDate: PropTypes.string,
  arrivalDates: PropTypes.string,
  setArrivalDate: PropTypes.func,
  deliveryDate: PropTypes.string,
  setDeliveryDate: PropTypes.func,
  setLoadDate: PropTypes.func,
  setStage: PropTypes.func,
  fetchProject: PropTypes.func,
  searcProducthData: PropTypes.func,
  isSearch: PropTypes.bool,
  setSearch: PropTypes.func,
  productDetails: PropTypes.array,
  setProductDetails: PropTypes.func,
  setFilterProductId: PropTypes.func


}

export default TableToolbar
